import throttle from "lodash/throttle";
import MobileDetect from "mobile-detect";
import { GetServerSidePropsContext } from "next";
import { createContext, useContext, useEffect, useState } from "react";

export const breadpoints = {
  sm: 576,
  md: 768,
  mdp: 820,
  lg: 992,
  xl: 1200,
  xlp: 1440,
};

export const getIsSsrMobile = (context: GetServerSidePropsContext) => {
  const md = new MobileDetect(String(context.req.headers["user-agent"]));

  return Boolean(md.mobile());
};

export const IsSsrMobileContext = createContext(false);

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = throttle(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100);

    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const useIsMobile = () => {
  const isSsrMobile = useContext(IsSsrMobileContext);
  const { width: windowWidth } = useWindowSize();
  const isBrowserMobile = !!windowWidth && windowWidth < breadpoints.md;

  return isSsrMobile || isBrowserMobile;
};
