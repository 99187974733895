import { datadogLogs } from "@datadog/browser-logs";
import { useEffect } from "react";

const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
const datadogSite = process.env.NEXT_PUBLIC_DATADOG_SITE;

let isInited = false;

export const useDatadog = () => {
  if (!clientToken) {
    throw new Error("Please define the NEXT_PUBLIC_DATADOG_CLIENT_TOKEN environment variable inside .env");
  }

  if (!datadogSite) {
    throw new Error("Please define the NEXT_PUBLIC_DATADOG_SITE environment variable inside .env");
  }

  useEffect(() => {
    if (!isInited) {
      datadogLogs.init({
        clientToken: clientToken,
        site: datadogSite,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
        service: "kdot-homepage-logs",
        // https://github.com/DataDog/browser-sdk/issues/362
        beforeSend: (event) => {
          // This is Google map error
          // https://stackoverflow.com/questions/55003463/uncaught-in-promise-event-istrusted-true
          if (event.message === `Uncaught {"isTrusted":true}`) {
            return false;
          }

          if (event.message.includes("Invariant: attempted to hard navigate to the same URL")) {
            event.status = "warn";
          }

          // This is issue of nextjs
          // https://github.com/vercel/next.js/issues/62678
          if (event.message.includes("Error: Route did not complete loading:")) {
            return false;
          }

          if (event.error && event.http?.status_code === 0) {
            return false;
          }
        },
      });
      datadogLogs.setGlobalContext({ env: process.env.NEXT_PUBLIC_VERCEL_ENV });
      isInited = true;
    }
  }, []);
};
