/* eslint-disable max-lines */
import { Noto_Sans_JP } from "@next/font/google";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import NextNProgress from "nextjs-progressbar";
import { FC } from "react";
import { useDatadog } from "@/components/useDatadog";
import { useGTM } from "@/components/useGTM";
import { useHotjar } from "@/components/useHotjar";
import { useIgnoreRouterError } from "@/components/useIgnoreRouterError";
import { IsSsrMobileContext } from "@/components/useWindowSize";
import "@/styles/globals.css";

const notoSansJp = Noto_Sans_JP({
  subsets: ["latin"],
  display: "swap",
  weight: ["400", "500"],
});

const App: FC<AppProps<{ isSsrMobile: boolean }>> = ({ Component, pageProps, router }) => {
  useDatadog();
  useGTM();
  useHotjar();
  useIgnoreRouterError(router);

  return (
    <IsSsrMobileContext.Provider value={pageProps.isSsrMobile}>
      <NextNProgress startPosition={0.1} options={{ showSpinner: false }} />
      <style jsx global>{`
        html,
        body,
        input,
        textarea,
        button {
          font-family: ${notoSansJp.style.fontFamily};
        }
      `}</style>
      <DefaultSeo
        title="株式会社KDOT"
        description="株式会社KDOT"
        openGraph={{
          title: "株式会社KDOT",
          type: "website",
          description: "株式会社KDOT",
        }}
        additionalMetaTags={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1",
          },
          {
            name: "msapplication-TileColor",
            content: "#a10000",
          },
          {
            name: "theme-color",
            content: "#ffffff",
          },
        ]}
        additionalLinkTags={[
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/favicon/apple-touch-icon.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/favicon/favicon-32x32.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/favicon/favicon-16x16.png",
          },
          {
            rel: "manifest",
            href: "/favicon/site.webmanifest",
          },
          {
            rel: "mask-icon",
            href: "/favicon/safari-pinned-tab.svg",
            color: "#a10000",
          },
        ]}
      />
      <Component {...pageProps} />
    </IsSsrMobileContext.Provider>
  );
};

export default App;
