import { Router } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { useEffect } from "react";

export function useIgnoreRouterError(router: Router) {
  useEffect(() => {
    const origin = router.handleRouteInfoError.bind(router);

    router.handleRouteInfoError = async (
      err: Error & {
        code?: unknown;
        cancelled?: boolean;
      },
      pathname: string,
      query: ParsedUrlQuery,
      as: string,
      routeProps: { shallow: boolean },
      loadErrorFail?: boolean,
    ) => {
      if (err instanceof Error && err.message === "Loading initial props cancelled") {
        throw err;
      }

      return await origin(err, pathname, query, as, routeProps, loadErrorFail);
    };

    return () => {
      router.handleRouteInfoError = origin;
    };
  }, [router]);
}
