import { useEffect } from "react";
import TagManager from "react-gtm-module";

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const useGTM = () => {
  if (googleTagManagerId == null) {
    throw new Error("Please define the NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID environment variable inside .env");
  }

  useEffect(() => {
    TagManager.initialize({ gtmId: googleTagManagerId });
  }, []);
};
