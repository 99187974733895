import Hotjar from "@hotjar/browser";
import { useEffect } from "react";

const hotjarId = process.env.NEXT_PUBLIC_HOTJAR_ID;
const hotjarVersion = 6;

export const useHotjar = () => {
  if (!hotjarId) {
    throw new Error("Please define the NEXT_PUBLIC_HOTJAR_ID environment variable inside .env");
  }
  const hotjarIdNum = parseInt(hotjarId);

  useEffect(() => {
    Hotjar.init(hotjarIdNum, hotjarVersion);
  }, [hotjarIdNum]);
};
